<template>
	<el-dialog top="1vh" title="申诉记录" width="80%" :visible.sync="is_show_in_page">
        <!-- 表格 -->
		<div class="tab_height">
			<el-table 
				size="small" 
				height="500px" 
				:data="list.data" 
				:border="true" 
				:stripe="true" 
			>
				<el-table-column label="运单id">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.id}}</div>
					</template>
				</el-table-column>
				<el-table-column label="运单编号" >
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.truck_tord_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="发起用户编号">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.user_num}}</div>
					</template>
				</el-table-column>
                <el-table-column label="发起用户姓名">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.user_name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="申诉内容">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.matter}}</div>
					</template>
				</el-table-column>
				<el-table-column label="发起申诉时间">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.appeal_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="处理人姓名">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.admin_name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="处理结果">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.admin_deal}}</div>
					</template>
				</el-table-column>
				<el-table-column label="处理时间">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.admin_deal_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="处理状态">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.status_text}}</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</el-dialog>
</template>
<script>
import {mapState} from 'vuex'
	export default {
		props:{
			is_show:Number,
			truck_tord_num:String,
		},
		data() {
			return {
				//页面是否显示
				is_show_in_page:false,

                list:{
                    data:[]
                }
			}
		},
		computed:{
			
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
			
				    this.init();
				}
			},
		},
		methods: {
			//初始化
			init(){
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'admin_appeal_list',
						truck_tord_num:this.truck_tord_num,
					},callback:(data)=>{
						for(let item of data.list){
							//申诉时间
							item.appeal_time_text=this.$my.other.totime(item.appeal_time);

							//处理时间
							if(item.admin_deal_time == 0){
								item.admin_deal_time_text='暂未处理'
							}else{
								item.admin_deal_time_text=this.$my.other.totime(item.admin_deal_time);
							}
							

							//发车时间是否强制校验
							switch(item.status){
								case '1':item.status_text="申诉中";break;
								case '2':item.status_text="已处理";break;
							}
						}
						this.list.data= data.list
					}
				})
			},
		}
	}
</script>
<style lang="scss" scoped>

	
</style>