 <template>
	<el-dialog title="开票信息" top="10vh"  width="600px" :visible.sync="is_show_in_page" @close="invoice_close">
        <div class="center">
            <div class="item">
                <div class="name">
                    公司名称:
                </div>
                <div>
                    {{company_info.name}}
                </div>
              
            </div>
            <div  class="item">
                <div class="name">
                    公司开户行:
                </div>
                <div>
                    {{company_info.bank_name}}
                </div>
              
            </div>
            <div  class="item">
                <div class="name">
                    公司银行卡号:
                </div>
                <div>
                    {{company_info.bank_card}}
                </div>
              
            </div>

            <div  class="item">
                <div class="name">
                    统一社会信用代码:
                </div>
                <div>
                   {{company_info.license_code}}
                </div>
            </div>
            <div  class="item">
                <div class="name">
                    公司地址:
                </div>
                <div>
                   {{company_info.addr}}
                </div>
            </div>
             <div  class="item">
                <div class="name">
                    公司电话:
                </div>
                <div>
                   {{company_info.tel}}
                </div>
            </div>
            <div  class="item">
                <div class="name" style="margin-top: 10px;">
                    邮寄地址
                </div>
                <!-- <span v-if="must_icon_show" style="margin-top: 12px;color:red;margin-right:20px">*</span> -->
              
                <div>
                   <el-button type="primary" @click="tord_invoice_addr">地址列表</el-button>
                </div>
                  <span v-if="!must_icon_show" style="margin-top: 12px;color:red;margin-right:20px">(已选择默认地址)</span>
              
            </div>
            <div  class="item">
                <div class="name">
                    已选择:
                </div>
                <div>
                   {{max}}条
                </div>
            </div>
            <div  class="item">
                <div class="name">
                    总开票金额:
                </div>
                <div>
                   {{payed_total_of_all}}元
                </div>
            </div>
            <div  class="item">
                <div class="name">
                    总运费:
                </div>
                <div>
                   {{freight_total}}元
                </div>
            </div>
            <div  class="item">
                <div class="name">
                    总吨数:
                </div>
                <div>
                   {{cargo_weight_all}}吨
                </div>
            </div>
            <div class="mark">
                <div style="width:120px;margin-top:25px;margin-left:5px;">备注(非必填)：</div>
                <el-input placeholder="起运地:
目的地：
货物名称：
车型：
车牌号：" type="textarea"  :rows="5" v-model="mark" style="margin-top:10px;">
        </el-input>
            </div>
             <div class="mark">
                <div style="width:120px;margin-top:25px;margin-left:5px;">特殊备注：</div>
                <el-input placeholder="无特殊备注请勿填" type="textarea"  v-model="ts_mark" style="margin-top:10px;">
                </el-input>
            </div> 
            <div class="mark">
                <div style="width:120px;margin-top:25px;margin-left:5px;">邮箱：</div>
                <el-input placeholder="选填" type="email"  v-model="email" style="margin-top:10px;">
                </el-input>
            </div> 
        </div>      
        <div slot="footer" class="dialog-footer">
            <el-button @click="cancel()">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
        </div>
          <!-- 收货地址弹窗 -->
		<invoiceAddr :is_show="tord_invoice_addr_end.is_show" @close ="init"></invoiceAddr>
	</el-dialog>
</template>

<script>
import {mapState} from 'vuex'
import invoiceAddr from './tord_invoice_addr.vue'
	export default {
        components:{
			invoiceAddr
		},
        props:{
			is_show:Number,
            data:Array
		},
		data() {
			return {
                is_show_in_page:false,
                payed_total_of_all:'',//开票总金额
                freight_total:'',//总运费
                max:'',//总条数
                cargo_weight_all:'',//总吨数
                mark:'',//备注
                ts_mark:'',//特殊备注
                //收货地址相关
                tord_invoice_addr_end:{
                    is_show:0,
                },
                must_icon_show:null,
                email:'',//邮箱
                company_info:'',
			}
		},
		watch:{
			is_show(new_data){
                if(new_data){
                    this.is_show_in_page=true
                }
			},
            data(new_data){
                this.init(new_data)
            }
		},
		computed:{
            ...mapState(['company_main_user_info'])
            
		},
		methods: {
            //打开收货地址弹窗
			tord_invoice_addr(){
				this.tord_invoice_addr_end.is_show++
			},
            //弹窗关闭
            invoice_close(){
                this.mark = ''
            },
            //初始化
            init(new_data){
                this.company_info=this.company_main_user_info.main_user_company_info
                // console.log(this.company_main_user_info)
                if(new_data){
                    this.max =new_data.length
                    let payed_total_of_all =0
                    let cargo_weight_all =0
                    let freight_total = 0
                    new_data.forEach(item => {
                        payed_total_of_all +=Number(item.payed_total_of_all)
                        cargo_weight_all +=Number(item.cargo_weight)
                        freight_total +=Number(item.freight_total)
                    });
                    this.payed_total_of_all = payed_total_of_all.toFixed(2)
                    this.cargo_weight_all = cargo_weight_all.toFixed(2)
                    this.freight_total = freight_total.toFixed(2)
                }

                //读取是否添加默认地址
                this.$my.net.req({
                    data:{
                        mod:'truck_tord_real_time',
                        ctr:'invoice_address_list',
                    },callback:(data)=>{
                        //有默认地址
                        if(data.address_list){
                            this.must_icon_show = false
                        }else{
                            this.must_icon_show = true
                        }


                    }
                })
            },
            confirm(){
                //提示
				this.$my.other.confirm({
					content:"您的操作是'批量申请开票',请确认",
					confirm:()=>{
                        //if(!this.must_icon_show){
                             //提交 有默认地址
                            this.$my.net.req({
                                data:{
                                    mod:'truck_tord_real_time',
                                    ctr:'tord_invoice_apply_by_shipper',
                                    apply_list:this.data,
                                    mark:this.mark,
                                    ts_mark:this.ts_mark,
                                    email:this.email,
                                },
                                callback:(data)=>{
                
                                    //提醒
                                    this.$my.other.msg({
                                        str:'操作成功',
                                        type:'success'
                                    });
                                    
                                    //关闭弹窗
                                    this.is_show_in_page=false
                                    this.$emit('invoice_success')
                                },
                            });
                        // }else{
                        //     //无默认地址
                        //         //提醒
                        //     this.$my.other.msg({
                        //         str:'请先添加邮寄地址',
                        //         type:'info'
                        //     });
                        // }

					}
				});
            },
		}
	}
</script>
<style lang="scss" scoped>
.mark{
    display:flex;
     margin-left: -10px;
     margin-top: 20px;
}
.center {
    margin-left: 20px;
    .item{
        margin-top: 20px;
        display:flex;
        .name{
            margin-right: 10px;
        }
    }
    
}
.must{
    color: red;
    margin-right: 5px;
    font-size: 15px;
    // margin-top: 10px;
}
</style>